import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Rectangle,
  Cell,
} from "recharts";
import { SingleBarChartProps } from "./types";

const barWidthDynamic: Record<string, number> = {};

const formatTick = (value: string, maxLength: number) => {
  const trimmedText = value.replace(/\s/g, "");
  return trimmedText.length > maxLength
    ? `${trimmedText.slice(0, maxLength)}...`
    : trimmedText;
};

const getBarWidth = (
  current: string,
  previous: string,
  barWidth: Record<string, number> | undefined,
  chartWidth: number,
  index: number
) => {
  if (index === 0) {
    barWidthDynamic[current] = barWidth ? barWidth[0] : chartWidth;
  }
  const width =
    barWidthDynamic[current] ?? (barWidth ? barWidth[index] : chartWidth);
  barWidthDynamic[current] = width;
  return width;
};

export const HorizontalSingleBar = ({
  data,
  barColor,
  labelColor = "#fff",
  labelWeight = 400,
  labelFamily = "Rethink Sans Variable",
  labelSize = "12px",
  displayGrid = true,
  displayToolTip = true,
  fill = "",
  stroke = "",
  cursor = "",
  yAxisStyle,
  barSize = 16,
  barWidth,
  margin = { top: 0, right: 0, left: 0, bottom: 0 },
  chartHeight,
  chartWidth,
  showAxisLine = true,
  showTickLine = true,
  showLabel = false,
  formatLabel = false,
  tickMaxLength = 10,
  yAxisKey,
  xAxisKey,
}: SingleBarChartProps) => {
  return (
    <BarChart
      layout="vertical"
      width={chartWidth}
      height={chartHeight}
      data={data}
      margin={margin}
    >
      {displayGrid && <CartesianGrid strokeDasharray="7 3" />}
      <XAxis type="number" hide />
      <YAxis
        width={70}
        type="category"
        dataKey={yAxisKey}
        axisLine={showAxisLine}
        tickLine={showTickLine}
        interval={0}
        style={yAxisStyle ?? {}}
        textAnchor="start"
        transform="translate(-67, 0)"
        tick={{ fill: yAxisStyle?.color ?? "#222" }}
        tickFormatter={(value) => formatTick(value, tickMaxLength)}
      />
      {displayToolTip && <Tooltip />}
      <Bar
        label={
          showLabel
            ? {
                position: "insideRight",
                offset: 20,
                fill: labelColor,
                fontWeight: labelWeight,
                fontFamily: labelFamily,
                fontSize: labelSize,
                formatter: (value: number) =>
                  formatLabel
                    ? new Intl.NumberFormat("en").format(value)
                    : value,
              }
            : ""
        }
        barSize={barSize}
        dataKey={xAxisKey}
        activeBar={<Rectangle fill={fill} stroke={stroke} />}
      >
        {data?.map((entry, index) => (
          <Cell
            cursor={cursor}
            width={barWidth ? barWidth[index] : chartWidth}
            fill={barColor && barColor[index]}
            key={`cell-${index}`}
            aria-label={entry.name}
          />
        ))}
      </Bar>
    </BarChart>
  );
};
