import { HorizontalSingleBar } from "../Charts/BarChart/HorizontalSingleBar";
import { SingleBarDataType } from "../Charts/BarChart/types";

const BAR_COLORS = {
  0: "#004f18",
  1: "#017429",
  2: "#018d32",
  3: "#008580",
  4: "#00ada7",
  5: "#a0e3e1",
};

const BAR_WIDTH = {
  0: 310,
  1: 243,
  2: 200,
  3: 169,
  4: 126,
  5: 94,
};

type ChartProps = {
  data: SingleBarDataType[];
  yAxisKey: string;
  xAxisKey: string;
};
export const Chart = ({ data, yAxisKey, xAxisKey }: ChartProps) => {
  return (
    <HorizontalSingleBar
      yAxisKey={yAxisKey}
      xAxisKey={xAxisKey}
      chartHeight={116}
      chartWidth={371}
      barColor={BAR_COLORS}
      barWidth={BAR_WIDTH}
      data={data}
      displayGrid={false}
      displayToolTip={false}
      labelColor="#fff"
      labelFamily={"Rethink Sans Variable"}
      labelSize={"12px"}
      labelWeight={600}
      barSize={16}
      yAxisStyle={{
        fontSize: "11px",
        fontFamily: "Rethink Sans Variable",
        color: "#222",
        fontWeight: 400,
      }}
      showAxisLine={false}
      showTickLine={false}
      showLabel={true}
      margin={{ top: 0, right: 0, left: 5, bottom: 0 }}
      formatLabel={true}
      tickMaxLength={10}
    />
  );
};
