import "../assets/css/LoadingDots.css";

const LoadingDots = () => {
  return (
    <div className="h-75 flex-grow-1">
      <div className="w-100 h-75 col d-flex justify-content-center align-items-center">
        <div className="loader-container circle-pulse-multiple d-flex align-items-center justify-content-center">
          <div className="loader text-center d-flex">
            <div className="me-2 dot dot-1"></div>
            <div className="dot dot-2"></div>
            <div className="ms-2 dot dot-3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingDots;
