import {
  ReactComponent as AutoIcon,
  ReactComponent as AutoOutlined,
} from "./assets/images/main/lob/auto-outline.svg";
import {
  BundledQuoteResponses,
  LineOfBusinessC,
  ManualQuoteResponse,
  MonolineLob,
  PropertyLob,
  Quote,
  TabName,
  TabStatus,
} from "./types";
import {
  ReactComponent as CondoIcon,
  ReactComponent as CondoOutlined,
} from "./assets/images/main/lob/condo-outline.svg";
import {
  ReactComponent as DwellingIcon,
  ReactComponent as DwellingOutlined,
} from "./assets/images/main/lob/dwelling-outline.svg";
import {
  ReactComponent as HomeIcon,
  ReactComponent as HomeOutlined,
} from "./assets/images/main/lob/home-outline.svg";
import {
  PACKAGE_REQUIRED_BUNDLE_PAGE_NEW,
  PACKAGE_REQUIRED_NOT_AVAILABLE,
  TABS_LOB,
} from "./constants";
import {
  ReactComponent as Renters,
  ReactComponent as RentersIcon,
} from "./assets/images/main/lob/renters-filled.svg";
import { getPageNameFromUrl, isDwelling } from "./utils";
import { useRef, useState } from "react";

import { ReactComponent as Auto } from "./assets/images/main/lob/auto-filled.svg";
import { ReactComponent as Condo } from "./assets/images/main/lob/condo-filled.svg";
import { Coverage } from "./redux/reducers/types";
import { ReactComponent as Dwelling } from "./assets/images/main/lob/dwelling-filled.svg";
import { ReactComponent as Flood } from "./assets/images/main/lob/flood-filled.svg";
import { ReactComponent as Home } from "./assets/images/main/lob/home-filled.svg";
import { ReactComponent as Jewelry } from "./assets/images/main/lob/jewelry-filled.svg";
import { ReactComponent as Life } from "./assets/images/main/lob/life-filled.svg";
import { ReactComponent as PeopleIcon } from "./assets/images/main/lob/people-outline.svg";
import { ReactComponent as PolicyIcon } from "./assets/images/main/lob/policy-outline.svg";
import { Property } from "./redux/reducers/propertyReducer";
import { ReactComponent as ResultsIcon } from "./assets/images/main/lob/results-outline.svg";
import { ReactComponent as Security } from "./assets/images/main/lob/security-filled.svg";
import { ReactComponent as Umbrella } from "./assets/images/main/lob/umbrella-filled.svg";
import { addContact } from "./redux/actions/contact";
import { getAccountDetails } from "./services/account";
import { getCopilotCarrierConfig } from "./services/quote";
import { getHomeQuotesTitle } from "./utils/quotes";
import store from "./redux/store";
import { useFeatureFlag } from "./services/featureFlag";
import { useOnClickOutside } from "usehooks-ts";

export const getAddress = (type: "property" | "contact") => {
  const data = store.getState()[type];
  if (!data.street_number) return null;

  const unitText = data.unitNumber ? `Unit ${data.unitNumber}, ` : "";
  return `${data.street_number}, ${unitText}${data.locality} ${data.administrative_area_level_1} ${data.postal_code}`;
};

/**
 * Capitalize the first letter of each word in a string. Includes handling for
 * hyphenated words.
 */
export const capitalizeFirstChars = (name?: string) => {
  if (!name) return "";

  const capitalizeFirstLetter = (word: string) =>
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();

  return name
    .replace(/\\'/g, "'")
    .replace(/\\"/g, '"')
    .split(" ")
    .map((word) => word.split("-").map(capitalizeFirstLetter).join("-"))
    .join(" ");
};

export const saveSfAccountId = async () => {
  const { accountId, sfAccountId } = store.getState().contact;
  if (sfAccountId) return sfAccountId;
  if (!accountId) return null;
  // WARNING: this will not fail with 404 if accountId is not found
  const { data } = await getAccountDetails(accountId);
  const result = data?.[0]?.id;
  if (!result || typeof result !== "string") {
    throw new Error("sfAccountId not found");
  }
  store.dispatch(
    addContact({
      sfAccountId,
    })
  );
  return result;
};

export const formatNumberToDollars = (
  maybeNumber?: number | string | null,
  options?: Intl.NumberFormatOptions,
  shouldUseQuestionMarkForEmpty = true
) => {
  const emptyResult = shouldUseQuestionMarkForEmpty ? "?" : "";

  if (!maybeNumber) {
    return emptyResult;
  }
  if (typeof maybeNumber === "string" && maybeNumber.toLowerCase() === "none")
    return "None";

  const number =
    typeof maybeNumber === "string" ? Number(maybeNumber) : maybeNumber;
  if (number === 0) return "None";
  if (isNaN(number)) return emptyResult;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
    ...options,
  }).format(number);
};

export const getHomeFloodPremiumPerYear = (quote: Quote) => {
  return quote.premium_total_pif__c || quote.premium_total__c;
};

export const getAutoPremiumPerYear = (quote: Quote) => {
  const numMonths = quote.term__c ? parseInt(quote.term__c) : null;
  if (quote.premium_total__c && numMonths) {
    return (quote.premium_total__c / numMonths) * 12;
  }
  if (quote.premium_total_ppm__c) return quote.premium_total_ppm__c * 12;
  return null;
};

export const getAutoJewelryLifePremiumPerMonth = (quote: Quote) => {
  if (quote.premium_total_ppm__c) {
    return quote.premium_total_ppm__c;
  }
  if (quote.premium_total__c && quote.term__c) {
    return quote.premium_total__c / parseInt(quote.term__c);
  }
  if (quote.premium_total_pif__c) {
    return quote.premium_total_pif__c / 12;
  }
  return null;
};

export const getPremiumWithUnit = (
  quote: Quote
): {
  unit: "mo" | "yr";
  premium: number | null;
} => {
  if (
    ["Home", "Flood", "Dwelling", "Condo"].includes(quote.line_of_business__c)
  ) {
    return {
      unit: "yr",
      premium: getHomeFloodPremiumPerYear(quote),
    };
  }
  return {
    unit: "mo",
    premium: getAutoJewelryLifePremiumPerMonth(quote),
  };
};

export const formatDeductible = (
  deductible: string | number | null,
  dwellingLimit: number | null
) => {
  if (!deductible || !dwellingLimit) return;
  if (deductible === "Excluded") return "Excluded";

  const deductibleNumber =
    typeof deductible === "number"
      ? deductible
      : Number(deductible.replace("$", "").replace("%", ""));

  if ([1, 0.01].includes(deductibleNumber)) return "1%";
  if ([2, 0.02].includes(deductibleNumber)) return "2%";
  if ([3, 0.03].includes(deductibleNumber)) return "3%";
  if ([5, 0.05].includes(deductibleNumber)) return "5%";

  if (deductible.toString().startsWith("00")) {
    return deductible.toString().slice(2);
  }

  const calculatedPercentage = (deductibleNumber / dwellingLimit) * 100;

  if ([1, 2, 5].includes(calculatedPercentage)) {
    return `${calculatedPercentage}%`;
  }
  return Number(deductibleNumber).toLocaleString("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
};

// TODO: refactor this
export const getAutoDetails = (coverage: Coverage) => ({
  liability:
    coverage.bodily_injury +
    "/" +
    (coverage.property_damage?.length ?? 0 > 3
      ? coverage.property_damage?.substring(
          0,
          coverage.property_damage.length - 3
        )
      : coverage.property_damage),
  umuim: coverage.uim,
  umuimumpd:
    coverage.uim +
    "/" +
    (coverage.uim_property_damage?.length ?? 0 > 3
      ? coverage.uim_property_damage?.substring(
          0,
          coverage.uim_property_damage?.length ?? 0 - 3
        )
      : coverage.uim_property_damage),
  comprehensive: coverage.comp,
  collision: coverage.collision,
  rental: coverage.rental || "30/900",
  towing: coverage.towing || "Yes",
  roadsize: coverage.towing || "Yes",
});

export const padNumbersWithZero = (number: number, maxLength = 2) => {
  return number.toString().padStart(maxLength, "0");
};

const getSuccessfulCheckoutStyles = (isBind: boolean, bridged: boolean) => {
  if (isBind) {
    return {
      checkoutBgColor: bridged ? "#017429" : "#dcffe8",
      checkoutTextColor: bridged ? "#dcffe8" : "#017429",
      checkoutBorder: bridged ? undefined : "1px solid #bbf2ce",
    };
  } else {
    return {
      checkoutBgColor: bridged ? "#006b67" : "#e8f4f8",
      checkoutTextColor: bridged ? "#e8f4f8" : "#006b67",
      checkoutBorder: bridged ? undefined : "1px solid #d8edf3",
    };
  }
};

export const getCheckoutInfo = (
  quote: Quote,
  isBind: boolean,
  bridged: boolean
): {
  hasButton: boolean;
  checkoutBgColor: string;
  checkoutTextColor: string;
  checkoutBorder?: string;
  checkoutText: string;
  infoText?: string;
  infoTextColor?: string;
} => {
  const contact = store.getState().contact;
  if (quote.line_of_business__c === "Security") {
    const hasProspectId = !!contact.prospectId;
    return {
      hasButton: true,
      ...getSuccessfulCheckoutStyles(false, hasProspectId),
      checkoutText: "View",
      infoText: hasProspectId ? "Referral Sent" : "Refer",
      infoTextColor: "#636363",
    };
  }

  const successfulStyles = getSuccessfulCheckoutStyles(isBind, bridged);
  if (quote.error_type__c === "Success") {
    const { premium, unit } = getPremiumWithUnit(quote);
    const priceWithUnit = `${formatNumberToDollars(premium)}/${unit}`;
    return {
      hasButton: true,
      ...successfulStyles,
      checkoutText: priceWithUnit,
      infoText: Number(quote.term__c) === 6 ? "6 months" : undefined,
      infoTextColor: "#636363",
    };
  }
  if (quote.error_type__c === "Carrier Error") {
    return {
      hasButton: true,
      ...successfulStyles,
      checkoutText: "Quote",
      infoText: "Carrier Error",
      infoTextColor: "#636363",
    };
  }
  if (
    ["Quote Directly", "Quote Directly in Carrier"].includes(
      quote.error_type__c
    )
  ) {
    return {
      hasButton: true,
      ...successfulStyles,
      checkoutText: "Quote",
      infoText: "Quote Directly",
      infoTextColor: "#636363",
    };
  }
  if (
    ["Approval Needed", "Ineligible/Approval Needed"].includes(
      quote.error_type__c
    )
  ) {
    return {
      hasButton: true,
      ...successfulStyles,
      checkoutText: "Bridge",
      infoText: "Approval Needed",
      infoTextColor: "#636363",
    };
  }
  if (quote.error_type__c === "Quote Manually") {
    return {
      hasButton: true,
      ...successfulStyles,
      checkoutText: "Quote",
      infoTextColor: "#636363",
    };
  }

  // Error button
  if (quote.error_type__c === "Login Error") {
    return {
      hasButton: true,
      checkoutBgColor: "#fcdee0",
      checkoutTextColor: "#d4111b",
      checkoutText: "Update",
      infoText: "Login Error",
      infoTextColor: "#d4111b",
    };
  }

  // Error no button
  if (quote.error_type__c === "Invalid Input") {
    return {
      hasButton: false,
      checkoutBgColor: "transparent",
      checkoutTextColor: "#d4111b",
      checkoutText: quote.error_type__c,
    };
  }
  if (quote.error_type__c === "Did Not Quote/See Details") {
    return {
      hasButton: false,
      checkoutBgColor: "transparent",
      checkoutTextColor: "#636363",
      checkoutText: "Did Not Quote",
      infoText: "See Details",
      infoTextColor: "#636363",
    };
  }
  if (quote.error_type__c?.endsWith("Ineligible")) {
    return {
      hasButton: false,
      checkoutBgColor: "transparent",
      checkoutTextColor: "#636363",
      checkoutText: "Ineligible",
      infoText: quote.error_type__c.replace("Ineligible", "").trim(),
      infoTextColor: "#636363",
    };
  }
  return {
    hasButton: false,
    checkoutBgColor: "transparent",
    checkoutTextColor: "#636363",
    checkoutText: quote.error_type__c,
  };
};

export const mapManualQuote = (
  manualQuote: ManualQuoteResponse,
  lob: MonolineLob
): Quote => {
  return {
    bridged__c: false,
    direct_quote__c: true,
    premium_total_pif__c: null,
    premium_total__c: null,
    premium_total_ppm__c: null,
    effective_date__c: null,
    request_date__c: null,
    carrier__c: manualQuote.carrier,
    flood_zone__c: null,
    company_client_id__c: manualQuote.carrierId,
    company_quote_number__c: null,
    company_url_text__c: null,
    line_of_business__c: lob.toUpperCase() as LineOfBusinessC,
    quote_response_rquid__c: null,
    quote_summary_url_text__c: null,
    remarks_truncated__c: "",
    rquid__c: null,
    status__c: "",
    quote_response_external_id__c: "",
    carrier_number__c: null,
    name: "",
    error_messages__c: "",
    extended_status__c: null,
    remarks__c: "",
    marketing_messages__c: null,
    company_url_long__c: manualQuote.website,
    company_url__c: manualQuote.website,
    quote_summary_url__c: null,
    quote_request_heroku_id: "",
    heroku_id: "",
    term__c: null,
    aop_deductible__c: null,
    w_h_deductible__c: null,
    dwelling_limit__c: null,
    personal_property__c: null,
    package_rate__c: false,
    roadside__c: null,
    um_uim_coverage__c: null,
    uninsured_motorist__c: null,
    uninsured_motorist_property_damage__c: null,
    rental_reimbursement__c: null,
    property_damage__c: null,
    personal_injury_protection__c: null,
    other_structures__c: null,
    loss_of_use__c: null,
    liability__c: null,
    comprehensive__c: null,
    collision__c: null,
    bodily_injury__c: null,
    auto_liability__c: null,
    all_other_deductible__c: null,
    carrier_login__c: "",
    recommended_package__c: false,
    life_coverage__c: null,
    error_type__c: "Quote Manually",
    result_text__c: null,
    premium_down_payment__c: null,
    discounts__c: null,
    prior_policy_number__c: null,
    session_id__c: null,
    application_id__c: null,
    fcra_notification_url__c: null,
    policy_number__c: null,
    name_of_prior_insurer__c: null,
    prior_insurer_lob__c: null,
    prior_insurer_expiration_date__c: null,
    prior_insurer_num_months__c: null,
    clue_report_url__c: null,
    replacement_cost_guide_status__c: null,
    clue_status__c: null,
    mvr_status__c: null,
    medical_payments__c: null,
    water_backup__c: null,
    quote_type: null,
    error_responsibility__c: null,
    checkout_started__c: false,
    qti_remarks__c: null,
    qti_remarks_truncated__c: null,
    qti_quote_id: null,
  };
};

export const getErrorMessage = (quote: Quote) =>
  quote.error_messages__c ||
  quote.remarks_truncated__c ||
  quote.remarks__c ||
  "Unknown Error - Please report this using the Feedback button.";

export const isSpouseQuote = (quote: Quote) => {
  if (quote.isSpouseQuote != null) return quote.isSpouseQuote;
  return quote.quote_response_external_id__c?.toLowerCase().includes("spouse");
};

export const getQuoteCardNameInfo = (quote: Quote, lob: MonolineLob) => {
  const contact = store.getState().contact;

  const {
    firstName: primaryFirstName,
    spousefirstName: sposueFirstName,
    spouseId,
  } = contact;
  const firstName = isSpouseQuote(quote) ? sposueFirstName : primaryFirstName;
  const isMarried = !!spouseId;
  const hasNameInDropdown = isMarried && lob !== "life";
  const hasNameInMiddle = isMarried && lob === "life";
  return { firstName, hasNameInDropdown, hasNameInMiddle };
};

export const filterUmbrellaQuotes = (autoQuotes: Quote[]) =>
  autoQuotes.filter(
    ({ line_of_business__c }) => line_of_business__c !== "Umbrella"
  );

export const getCarrierListForContact = (
  lob: PropertyLob | "Auto"
): string[] => {
  const { homeQuoteId, autoQuoteId } = store.getState().contact;
  const isBundle = !!homeQuoteId && !!autoQuoteId;
  const { quotes } = store.getState().quote;

  if (lob === "Auto") {
    const autoCarriers = new Set([
      ...filterUmbrellaQuotes(quotes?.auto ?? []).map(
        (quote) => quote.carrier__c
      ),
      ...(isBundle && quotes?.bundle
        ? quotes.bundle.map((quote) => quote.autoQuote.carrier__c)
        : []),
    ]);

    return Array.from(autoCarriers).toSorted();
  }
  // property lob
  const homeCarriers = new Set([
    ...(quotes?.home ?? []).map((quote) => quote.carrier__c),
    ...(isBundle && quotes?.bundle
      ? quotes.bundle.map((quote) => quote.homeQuote.carrier__c)
      : []),
  ]);
  return Array.from(homeCarriers).toSorted();
};

export const useDropdown = (props?: { onClose: () => void }) => {
  const [show, setShow] = useState(false);
  const toggle = () => setShow((prev) => !prev);
  const close = () => {
    setShow(false);
    // reset to all carriers if none are checked
    // never allow this to be empty so that users don't see an empty screen
    props?.onClose?.();
  };

  const clickOutsideRef = useRef(null);
  const handleClickOutside = () => {
    close();
  };
  useOnClickOutside(clickOutsideRef, handleClickOutside);

  return {
    show,
    toggle,
    close,
    clickOutsideRef,
  };
};

export const getFilterDropdownButtonStyles = (
  text: string,
  defaultText: string
) => {
  if (text === defaultText) {
    return {
      backgroundColor: "white",
      borderColor: "#d8d7d4",
      color: "#535353",
    };
  }
  return {
    backgroundColor: "#d7fba2",
    borderColor: "#90bc4d",
    color: "#004c18",
  };
};

export const peekCarrierNames = ({
  lob,
  carrierNames,
  allCarrierNamesForContact,
  defaultValue,
}: {
  lob: PropertyLob | "Auto";
  carrierNames: string[];
  allCarrierNamesForContact: string[];
  defaultValue: string;
}) => {
  // carriers in carrierNames might not be in "allCarrierNamesForContact"
  // when switching contact, so filter out the ones that are not
  const numCarriers = carrierNames.filter((name) =>
    allCarrierNamesForContact.includes(name)
  ).length;
  const totalLength = allCarrierNamesForContact.length;

  if (numCarriers === 0 || numCarriers === totalLength) return defaultValue;
  if (numCarriers === 1) return carrierNames[0];
  return `${lob}: ${numCarriers} of ${totalLength}`;
};

export const sessionLob = () => store.getState().session?.line_of_business;
export const isMultiLine = () => (sessionLob()?.indexOf("Auto") ?? 0) > 0;
export const hasHomeOrDwellingQuote = () => sessionLob()?.includes("Home");
export const hasRentersQuote = () => sessionLob()?.includes("Renter");
export const hasCondoQuote = () => sessionLob()?.includes("Condo");
export const hasPropertyQuote = () => sessionLob() !== "Auto";
export const isAutoOnly = () => sessionLob() === "Auto";
export const hasAutoQuote = () => sessionLob()?.includes("Auto");
export const hasBundles = () => isMultiLine() && !isDwelling();
export const isEmptyBundle = (bundle: BundledQuoteResponses): boolean => {
  return Object.values(bundle).every((arr) => arr.length === 0);
};

export const getSessionPropertyLob = (): PropertyLob | undefined => {
  if (isAutoOnly()) return undefined;
  if (isDwelling()) return "Dwelling";
  if (hasCondoQuote()) return "Condo";
  if (hasRentersQuote()) return "Renter's";
  // impossible to be dwelling here
  if (hasHomeOrDwellingQuote()) return "Home";
  // impossible
  return sessionLob()?.replace("Auto", "") as PropertyLob;
};

const getFloodZoneStyles = (floodZone: string | null) => {
  if (floodZone === null) {
    return null;
  }
  if (["A", "V"].includes(floodZone)) {
    return {
      color: "#8e0b12",
      backgroundColor: "#fbc1c4",
    };
  }
  return {
    color: "#214537",
    backgroundColor: "#d9ede5",
  };
};

type FloodZoneInfo =
  | {
      hasFloodZone: true;
      floodZone: string;
      floodZoneStyles: {
        color: string;
        backgroundColor: string;
      };
    }
  | {
      hasFloodZone: false;
      floodZone: null;
      floodZoneStyles: null;
    };
export const getFloodZoneInfo = (quote: Quote) => {
  const floodZone = quote.flood_zone__c?.substring(0, 1) ?? null;
  const hasFloodZone =
    !!floodZone &&
    quote.line_of_business__c === "Flood" &&
    !!quote.premium_total__c;
  const floodZoneStyles = getFloodZoneStyles(floodZone);
  return {
    hasFloodZone,
    floodZone,
    floodZoneStyles,
  } as FloodZoneInfo;
};

export const getPackageRequiredPrompt = (monolineIneligibleQuotes: Quote[]) => {
  if (!isDwelling() && isMultiLine()) return PACKAGE_REQUIRED_BUNDLE_PAGE_NEW;
  if (!monolineIneligibleQuotes.length) return PACKAGE_REQUIRED_NOT_AVAILABLE;
  return null;
};

// TODO: remove after old design is removed
export const useIsNewResponsePageDesign = () => {
  const contact = store.getState().contact;
  const { flow, showNewResponsePage } = contact;

  const featureFlags = useFeatureFlag();
  const newResponsePageFlag =
    featureFlags.getFlag("aviator-new-response-page") ?? false;

  const isNewResponseFlow = flow === "newResponses";

  return isNewResponseFlow || showNewResponsePage || newResponsePageFlag;
};

export const getPropertyAddressFromSessionPropertyData = (
  propertyData?: string
) => {
  if (!propertyData) return "";
  const property: Property = JSON.parse(propertyData);
  const unitText = property.unitNumber ? `Unit ${property.unitNumber}, ` : "";
  return `${property.street_number}, ${unitText}${property.locality} ${property.administrative_area_level_1} ${property.postal_code}`;
};

export const isMonolineAcceptable = (
  company_client_id__c: string,
  type: "home" | "auto",
  stateConfigs: any,
  state: string,
  county: string
): boolean => {
  const carriers = stateConfigs[state]?.[type];

  if (carriers?.[company_client_id__c]) {
    if (
      carriers[company_client_id__c].isMonolineIneligibleInCertainCounties &&
      carriers[company_client_id__c].monolineIneligibleCounties.includes(county)
    ) {
      return true;
    }
    return carriers[company_client_id__c].isMonolineAcceptable;
  }
  return true;
};

export const getMonolineQuotes = (
  quotes: Quote[],
  type: "home" | "auto",
  isMultiLine: boolean,
  stateConfigs: any,
  state: string,
  county: string
): Quote[] => {
  return quotes.filter(({ company_client_id__c, package_rate__c }) => {
    if (package_rate__c === null) {
      return false;
    }
    const monolineAcceptable = isMonolineAcceptable(
      company_client_id__c,
      type,
      stateConfigs,
      state,
      county
    );
    const noPackageRateAndMonolineAcceptable =
      !package_rate__c && monolineAcceptable;
    const packageRateAndNotMonolineAcceptable =
      package_rate__c && !monolineAcceptable;
    return (
      noPackageRateAndMonolineAcceptable || packageRateAndNotMonolineAcceptable
    );
  });
};

export const trimStringToMaxLength = (str: string, maxLength: number): string =>
  str.length > maxLength ? str.substring(0, maxLength) + "..." : str;

const OutlinedMonolineLobIconMap = new Map<
  MonolineLob | "condo" | "dwelling" | "renters",
  typeof Home
>([
  ["home", HomeOutlined],
  ["auto", AutoOutlined],
  ["dwelling", DwellingOutlined],
  // Same icon
  ["renters", Renters],
  ["condo", CondoOutlined],
]);

const FilledMonolineLobIconMap = new Map<
  MonolineLob | "condo" | "dwelling" | "renters",
  typeof Home
>([
  ["home", Home],
  ["auto", Auto],
  ["flood", Flood],
  ["life", Life],
  ["jewelry", Jewelry],
  ["umbrella", Umbrella],
  ["security", Security],
  ["dwelling", Dwelling],
  ["renters", Renters],
  ["condo", Condo],
]);

export const getLobIcon = (
  lob: MonolineLob,
  variant: "filled" | "outline" = "filled"
) => {
  const lobMap =
    variant === "outline"
      ? OutlinedMonolineLobIconMap
      : FilledMonolineLobIconMap;

  if (lob !== "home") {
    return lobMap.get(lob)!;
  }
  // home lob icons depends on exact home quote type
  if (hasHomeOrDwellingQuote()) {
    return isDwelling() ? lobMap.get("dwelling") : lobMap.get("home");
  }
  if (hasRentersQuote()) {
    return lobMap.get("renters");
  }
  if (hasCondoQuote()) {
    return lobMap.get("condo");
  }
  return lobMap.get("home");
};

export const storeDataInCopilot = async ({
  carrierId,
  isSpouse,
}: {
  carrierId: string;
  isSpouse: boolean;
}) => {
  const {
    contact: { homeQuoteId, autoQuoteId },
  } = store.getState();
  try {
    const copilotCarrierConfig = await getCopilotCarrierConfig({
      isSpouse,
      carrierId,
      autoQuoteId,
      homeQuoteId,
    });
    if (!copilotCarrierConfig) {
      throw new Error("Copilot carrier config not found");
    }
    // This event will trigger the Copilot extension to store the data
    // in the extension's local storage
    const event = new CustomEvent("storeDataInExtension", {
      detail: JSON.stringify(copilotCarrierConfig),
    });
    document.dispatchEvent(event);
  } catch (error) {
    console.error("Error storing data in Copilot", error);
  }
};

export const isEligibleForCopilot = (quote: Quote): boolean => {
  return (
    quote.company_client_id__c === "391" && // Allstate
    ["Quote Manually", "Quote Directly in Carrier", "Carrier Error"].includes(
      quote.error_type__c
    )
  );
};

export const isResponsesPage = () => getPageNameFromUrl() === "Responses";
const HomeLobIconMap = {
  Home: HomeIcon,
  Renters: RentersIcon,
  Dwelling: DwellingIcon,
  Condo: CondoIcon,
};
const TAB_ICON_MAP = {
  Policy: PolicyIcon,
  Info: PeopleIcon,
  Drivers: AutoIcon,
  Vehicles: AutoIcon,
  Details: AutoIcon,
  Results: ResultsIcon,
};

export const getTabIcon = (tab: TabName) => {
  if (tab === "Home Details") {
    return HomeLobIconMap[getHomeQuotesTitle()];
  }
  return TAB_ICON_MAP[tab];
};

export const getTabIconStyles = (status: TabStatus) => {
  if (status === "disabled")
    return {
      color: "#939497",
      backgroundColor: "transparent",
    };
  if (status === "current")
    return {
      color: "white",
      backgroundColor: "#6cb545",
    };
  // status === "normal"
  return {
    color: "#6cb545",
    backgroundColor: "transparent",
  };
};

export const getTabStatus = ({
  tab,
  index,
  currentIndex,
}: {
  tab: TabName;
  index: number;
  currentIndex: number;
}) => {
  const {
    contact: {
      isReshop,
      isHomePolicySelected,
      addSkippedAutoQuote,
      addSkippedHomeQuote,
      quotesSubmitted,
      fieldsEdited,
    },
  } = store.getState();
  // special case for reshop flow policy tab
  if (isReshop && isHomePolicySelected && tab === "Policy") return "disabled";

  // auto only quote, in home tabs
  if (!hasPropertyQuote() && TABS_LOB.home.includes(tab)) return "disabled";
  // property only quote, in auto tabs
  if (!hasAutoQuote() && TABS_LOB.auto.includes(tab)) return "disabled";

  const isCurrent = index === currentIndex;

  // going back to the previous tabs shouldn't disable tabs unless something is changed
  const isInfoChangedAfterSubmitting =
    !quotesSubmitted ||
    addSkippedHomeQuote ||
    addSkippedAutoQuote ||
    fieldsEdited;
  const isDisabled = isInfoChangedAfterSubmitting && index > currentIndex;

  return isDisabled ? "disabled" : isCurrent ? "current" : "normal";
};

const COVERAGES_STATE_CONFIG = {
  w_h_deductible__c: [
    "AL",
    "AK",
    "AZ",
    "CO",
    "CT",
    "DC",
    "DE",
    "GA",
    "ID",
    "IL",
    "IN",
    "KS",
    "KY",
    "LA",
    "MO",
    "MN",
    "NV",
    "NJ",
    "NM",
    "NY",
    "NC",
    "OH",
    "OK",
    "OR",
    "PA",
    "SC",
    "TN",
    "TX",
    "UC",
    "VA",
    "WA",
    "WI",
    "WY",
  ],
  umuimumpd: [
    "AR",
    "CA",
    "DC",
    "FL",
    "GA",
    "ID",
    "IN",
    "KY",
    "LA",
    "MD",
    "MS",
    "MT",
    "NJ",
    "NM",
    "NC",
    "OH",
    "OR",
    "RI",
    "SC",
    "TN",
    "TX",
    "UT",
    "VT",
    "VA",
    "WA",
    "WV",
  ],
  umuim: [
    "AL",
    "AK",
    "AZ",
    "CT",
    "CO",
    "FL",
    "DE",
    "HI",
    "IL",
    "IA",
    "KS",
    "ME",
    "MI",
    "MN",
    "MO",
    "NE",
    "NV",
    "NH",
    "NY",
    "ND",
    "OK",
    "PA",
    "SD",
    "WI",
    "WY",
  ],
  pip: [
    "AR",
    "DE",
    "KY",
    "MD",
    "MN",
    "NJ",
    "NY",
    "NC",
    "ND",
    "OR",
    "TX",
    "UT",
    "WA",
  ],
  medPay: [
    "AK",
    "AL",
    "AZ",
    "CA",
    "CO",
    "CT",
    "DC",
    "FL",
    "GA",
    "HI",
    "IA",
    "ID",
    "IL",
    "IN",
    "KS",
    "LA",
    "MA",
    "ME",
    "MN",
    "MO",
    "MS",
    "MT",
    "ND",
    "NE",
    "NH",
    "NM",
    "NV",
    "OH",
    "OK",
    "RI",
    "SC",
    "SD",
    "TN",
    "UT",
    "VA",
    "VT",
    "WI",
    "WV",
    "WY",
  ],
};

export const isFieldVisible = (field: keyof typeof COVERAGES_STATE_CONFIG) => {
  const state = store.getState().contact.administrative_area_level_1;
  if (!state) return false;
  return COVERAGES_STATE_CONFIG[field].includes(state);
};
