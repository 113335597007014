import {
  ERROR_TYPES_SORTED_BETWEEN_SPOUSES,
  UMBRELLA_LINE_OF_BUSINESS,
  VALIDATION_DISPLAY_RULES,
  auto,
  bundle,
  home,
} from "../constants";
import { bundlesSortComparison, isDwelling, sortQuotes } from "../utils";
import { getBranchDrivers, getInitialBranchQuote } from "../services/qti";

import { addContact } from "../redux/actions/contact";
import { capitalizeFirstChars } from "./strings";
import { getQuoteRequest } from "../services/quote";
import { handleRecalculateQuote } from "./qti";
import { handleUpdateSession } from "../services/utils";
import store from "../redux/store";
import { updateIsFetchingPriorLimits } from "../redux/actions/fetching";

//this is false for dwelling at this point, will revisit after release
//for now calling the function each time we need to read dwelling
//const isDwelling = isDwellingFunction();

const formatCarrierName = (carrierNames) =>
  carrierNames.map(capitalizeFirstChars).sort();

export const getCarrierList = (quotes, lob, contact) => {
  if (lob === home) {
    const homeCarriers = new Set();
    if (contact.homeQuoteId && contact.autoQuoteId) {
      quotes[bundle].forEach((bundleQuote) =>
        homeCarriers.add(bundleQuote.homeQuote.carrier__c)
      );
    }
    quotes[home].forEach((quote) => homeCarriers.add(quote.carrier__c));
    return formatCarrierName(Array.from(homeCarriers));
  }
  if (lob === auto) {
    const autoCarriers = new Set();
    if (contact.homeQuoteId && contact.autoQuoteId) {
      quotes[bundle].forEach((bundleQuote) =>
        autoCarriers.add(bundleQuote.autoQuote.carrier__c)
      );
    }
    quotes[auto].forEach((quote) => autoCarriers.add(quote.carrier__c));
    return formatCarrierName(Array.from(autoCarriers));
  }
};

const includesIfNotNull = (list, value) => {
  // if list is null, no filter exists, so return true
  if (!list) return true;
  return list.includes(value);
};

export const filterQuotes = (quotes, homeCarriers, autoCarriers) => {
  const filteredBundledQuotes = quotes[bundle]
    .filter(
      (quote) =>
        includesIfNotNull(homeCarriers, quote.homeQuote.carrier__c) &&
        includesIfNotNull(autoCarriers, quote.autoQuote.carrier__c)
    )
    .sort(bundlesSortComparison);

  const filteredHomeQuotes = quotes[home].filter((quote) =>
    includesIfNotNull(homeCarriers, quote.carrier__c)
  );
  const filteredAutoQuotes = quotes[auto].filter(
    (quote) =>
      includesIfNotNull(autoCarriers, quote.carrier__c) &&
      quote.line_of_business__c !== UMBRELLA_LINE_OF_BUSINESS
  );
  return { filteredBundledQuotes, filteredHomeQuotes, filteredAutoQuotes };
};

export const validateStateToDisplay = (quotes) => {
  const { property } = store.getState();
  const quotesFilteredByState = quotes.filter((quote) => {
    const carrierRules = VALIDATION_DISPLAY_RULES[quote.company_client_id__c];
    const lineOfBusiness = quote.line_of_business__c.toLowerCase();
    if (carrierRules) {
      return !carrierRules[lineOfBusiness]?.notAvailableInStates?.includes(
        property.administrative_area_level_1
      );
    }
    return true;
  });
  return quotesFilteredByState;
};

const formatDeductible = (deductible, dwelling) => {
  if ([null, undefined, ""].includes(deductible)) return "Unknown";
  if (deductible === "Excluded") return "EXCL";

  const deductibleValue =
    typeof deductible === "number"
      ? deductible
      : parseFloat(deductible.replace("$", ""));
  if (isNaN(deductibleValue)) return "Unknown";

  const DEDUCTIBLE_PERCENTAGES = [1, 2, 3, 5];

  if (DEDUCTIBLE_PERCENTAGES.includes(deductibleValue)) {
    return Intl.NumberFormat("en-US", { style: "percent" }).format(
      deductibleValue / 100
    );
  }
  if (DEDUCTIBLE_PERCENTAGES.includes(deductibleValue * 100)) {
    return Intl.NumberFormat("en-US", { style: "percent" }).format(
      deductibleValue
    );
  }

  const deductiblePercentage = deductibleValue / dwelling;
  if (DEDUCTIBLE_PERCENTAGES.includes(deductiblePercentage * 100)) {
    return Intl.NumberFormat("en-US", { style: "percent" }).format(
      deductiblePercentage
    );
  }

  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  }).format(deductibleValue);
};

export const getHomeQuotesTitle = () => {
  const { session } = store.getState();
  const sessionLob = session.line_of_business?.toLowerCase() || "";

  if (sessionLob.includes("condo")) return "Condo";
  if (sessionLob.includes("renter")) return "Renters";

  return isDwelling() ? "Dwelling" : "Home";
};

export const getBestQuotes = (quoteResponsesData, homeOrAuto) => {
  const contact = store.getState().contact;
  const hasSpouseQuotes = !!contact.spouseId;

  if (!hasSpouseQuotes || contact.insuredContact === "primary") {
    const quotes = quoteResponsesData.primary[homeOrAuto];
    const bestQuotes = quotes.map((quote) => ({
      ...quote,
      isSpouseQuote: false,
    }));
    return bestQuotes;
  }

  if (contact.insuredContact === "spouse") {
    const quotes = quoteResponsesData.spouse[homeOrAuto];
    const bestQuotes = quotes.map((quote) => ({
      ...quote,
      isSpouseQuote: true,
    }));
    return bestQuotes;
  }

  const carrierQuoteMap = new Map();

  for (const quote of quoteResponsesData.primary[homeOrAuto]) {
    const carrierKey = quote.package_rate__c
      ? `${quote.carrier__c}-package`
      : quote.carrier__c;

    carrierQuoteMap.set(carrierKey, { ...quote, isSpouseQuote: false });
  }

  for (const quote of quoteResponsesData.spouse[homeOrAuto]) {
    const carrierKey = quote.package_rate__c
      ? `${quote.carrier__c}-package`
      : quote.carrier__c;

    if (!carrierQuoteMap.has(carrierKey)) {
      // available in spouse, but not in primary
      carrierQuoteMap.set(carrierKey, { ...quote, isSpouseQuote: true });
    } else {
      // available in both, so use the cheaper one
      const primaryQuote = carrierQuoteMap.get(carrierKey);
      const primaryPremium =
        carrierQuoteMap.get(carrierKey).premium_total__c ?? Infinity;
      const spousePremium = quote.premium_total__c ?? Infinity;
      if (primaryPremium === Infinity && spousePremium === Infinity) {
        const aIndex = ERROR_TYPES_SORTED_BETWEEN_SPOUSES.findIndex((status) =>
          (primaryQuote.error_type__c ?? "success").includes(status)
        );
        const bIndex = ERROR_TYPES_SORTED_BETWEEN_SPOUSES.findIndex((status) =>
          (quote.error_type__c ?? "success").includes(status)
        );
        if (bIndex < aIndex) {
          carrierQuoteMap.set(carrierKey, { ...quote, isSpouseQuote: true });
        }
      } else if (spousePremium < primaryPremium)
        carrierQuoteMap.set(carrierKey, { ...quote, isSpouseQuote: true });
    }
  }
  const bestQuotes = sortQuotes([...carrierQuoteMap.values()]);
  return bestQuotes;
};

export const getUnbundledQuotes = (quotes, isMultiLine) =>
  quotes.filter((quote) => {
    if (isMultiLine && !isDwelling()) {
      return !quote.package_rate__c && quote.company_client_id__c;
    }
    return quote.company_client_id__c;
  });

export const handleBranchQuote = async (forPriorLimitsAndAccidents = false) => {
  const { contact, property, session } = store.getState();
  const { dispatch } = store;
  if (forPriorLimitsAndAccidents) {
    dispatch(updateIsFetchingPriorLimits(true));
  }
  try {
    const branchQuote = await getInitialBranchQuote({
      firstName: contact.firstName,
      lastName: contact.lastName,
      contactId: contact.contactId,
      propertyStreetNumber: property.street_number,
      propertyLocality: property.locality,
      propertyState: property.administrative_area_level_1,
      propertyPostalCode: property.postal_code,
      agentId: contact.user || session.agent_sfid,
      priorAddressStreet: contact.street_number,
      priorAddressCity: contact.locality,
      priorAddressState: contact.administrative_area_level_1,
      priorAddressZip: contact.postal_code,
      homeQuoteId: contact.homeQuoteId,
      autoQuoteId: contact.autoQuoteId,
      propertyId: contact.propertyId,
      lob: session.line_of_business,
      forPriorLimitsAndAccidents,
    });
    if (!branchQuote.data.error) {
      let hasUDR = false;
      let claimCount = 0;
      let violationCount = 0;
      let homeClaimCount;
      if (contact.homeQuoteId) {
        const homeQuoteRequest = await getQuoteRequest(contact.homeQuoteId);
        homeClaimCount =
          homeQuoteRequest.data[0].data_source_home_claim_count__c;
      }

      if (contact.autoQuoteId && forPriorLimitsAndAccidents) {
        const branchDriversData = await getBranchDrivers(contact.autoQuoteId);

        const branchDrivers = branchDriversData.data;
        for (let i = 0; i < branchDrivers.length; i += 1) {
          if (branchDrivers[i].has_udr) {
            hasUDR = true;
          }
          claimCount += branchDrivers[i].data_source_claims_count__c || 0;
          violationCount +=
            branchDrivers[i].data_source_violation_count__c || 0;
        }

        await handleUpdateSession({
          uuid: session.uuid,
          contact_data: JSON.stringify({
            ...contact,
            hasUDR: hasUDR,
            claimCount: claimCount,
            violationCount: violationCount,
            homeClaimCount: homeClaimCount,
          }),
        });

        dispatch(
          addContact({
            hasUDR: hasUDR,
            claimCount: claimCount,
            violationCount: violationCount,
            homeClaimCount: homeClaimCount && parseInt(homeClaimCount),
          })
        );
      }

      if (!forPriorLimitsAndAccidents) {
        await handleUpdateSession({
          uuid: session.uuid,
          qti_id: branchQuote.data.offerId,
        });

        const isInitialRecalculate = true;
        if (!branchQuote.data.isDriverAndVehicleDataFromBranch)
          handleRecalculateQuote(
            {
              recalculateType: "Full",
            },
            branchQuote.data.offerId,
            true,
            isInitialRecalculate
          );
      }
      dispatch(updateIsFetchingPriorLimits(false));
    } else {
      console.log("branchQuote.data.error", branchQuote.data.error);
      dispatch(updateIsFetchingPriorLimits(false));
    }
  } catch (error) {
    console.log("Something went wrong", error);
    dispatch(updateIsFetchingPriorLimits(false));
  }
};

// if less than 8 quotes are available, add some margin to bottom
// so that clicking through tabs doesn't scroll the page
export const calculateQuoteListMarginBottom = (nQuotes) => {
  const QUOTE_HEIGHT = 90;
  const MIN_QUOTE_WO_WHITESPACE = 8;
  const MIN_MARGIN_BOTTOM = 35;
  return Math.max(
    (MIN_QUOTE_WO_WHITESPACE - nQuotes ?? 0) * QUOTE_HEIGHT,
    MIN_MARGIN_BOTTOM
  );
};

/*This function takes in an array of bundles and returns the best bundle for
each combination of home and auto quotes.*/
export const getBestBundles = (bundles) => {
  const bestBundles = bundles?.reduce((acc, bundle) => {
    const homeClientId = bundle?.homeQuote.company_client_id__c;
    const autoClientId = bundle?.autoQuote.company_client_id__c;
    const key = `home-${homeClientId}$auto-${autoClientId}`;

    if (!acc[key] || bundle?.bundledPrice < acc[key]?.bundledPrice) {
      acc[key] = bundle;
    }
    return acc;
  }, {});
  const sortedBundles = Object.values(bestBundles).sort(
    (a, b) => a.bundledPrice - b.bundledPrice
  );
  return sortedBundles;
};

export const mapCoveragesFromDownload = (deductible, dwellingLimit) => {
  if (!deductible || !dwellingLimit) {
    return;
  }
  const deductibleNumber = parseFloat(deductible);

  if (deductible === "Excluded") {
    return "Excluded";
  }

  if (
    deductibleNumber === 0.01 ||
    deductibleNumber === 1 ||
    deductibleNumber === 1.0
  ) {
    return "1%";
  } else if (
    deductibleNumber === 0.02 ||
    deductibleNumber === 2 ||
    deductibleNumber === 2.0
  ) {
    return "2%";
  } else if (
    deductibleNumber === 0.05 ||
    deductibleNumber === 5 ||
    deductibleNumber === 5.0
  ) {
    return "5%";
  } else if (deductible.startsWith("00")) {
    return deductible.slice(2);
  } else {
    // Replace 'dwellingLimit' with the actual Dwelling Limit value
    const calculatedPercentage = (deductibleNumber / dwellingLimit) * 100;

    if (calculatedPercentage === 1) {
      return "1%";
    } else if (calculatedPercentage === 2) {
      return "2%";
    } else if (calculatedPercentage === 5) {
      return "5%";
    } else {
      return calculatedPercentage.toString(); // Convert to string before returning
    }
  }
};
